import "./App.css";

import { useState } from "react";
import { useAlert } from "react-alert";
import { AiOutlineUser } from "react-icons/ai";

import logoImg from "./images/logo.png";
import LoadingGifs from "./images/YAAMS-gif.gif";

function App() {
  const [email, setEmail] = useState("");
  const [QR, setQR] = useState("");
  const [Loading, setLoading] = useState(false);

  const alert = useAlert();

  const generateWFHQRHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    const headersList = {
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      official_email: email,
    });

    const url = "https://api.yaams.net/login-for-wfh/";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) {
        setQR(data.data);
        alert.success(data.message);
        setLoading(false);
      } else {
        alert.error(data.message);
        setLoading(false);
      }
      console.log(data);
    } catch (err) {
      alert.error("Internal Server Error");
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <div className="row loginContainer">
      <div className="col-md-8">
        <div className="bannerImage d-fle" />
      </div>
      <div className="col-md-4 loginHighlight  loginLeftContainer position-relative">
        <div className="logo">
          <img src={logoImg} alt="YAAMS Logo" />
        </div>
        <form autoComplete onSubmit={generateWFHQRHandler}>
          <div className="loginWrapper">
            <h3 style={{ color: "red" }}>WFH Login</h3>
            <div className="position-relative">
              <input
                className="loginNewSearch"
                type="email"
                name="email"
                placeholder="Enter Your Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <AiOutlineUser className="position-absolute userIcon" />
            </div>
            <div
              className="position-relative mt-2"
              style={{ textAlign: "right", width: "90%" }}
            ></div>
            <div className="mt-2">
              <button className="loginButton">Generate QR</button>
            </div>

            {Loading ? (
              <img src={LoadingGifs} alt="Loading....." className="img-fluid" />
            ) : QR === "" ? (
              ""
            ) : (
              <div>
                <img
                  src={QR}
                  alt={email + "'s QR"}
                  className="img-fluid"
                  style={{ height: "320px", width: "320px" }}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
